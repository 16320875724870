import { useDomainContext } from "@hooks/useDomainContext";
import React, { ReactElement } from "react";
import styles from "./legal.module.scss";

export default function PrivacyPolicy({
    domain,
    category = "Auto Warranty and Car insurance",
}: {
    domain?: string;
    category: string;
}): ReactElement {
    const {
        domain: { name: actualDomainName, niceName },
    } = useDomainContext();
    const domainName = niceName ?? actualDomainName ?? domain;

    return (
        <div className={styles["legal"]}>
            <section>
                <p>
                    This Privacy Policy ("Policy") of Traffic Box LLC owner and
                    operator of {domainName} ("Website," "us," "our," or "we"),
                    which will always be at{" "}
                    <a href="/privacy-policy">{domainName}/privacy-policy</a> is
                    intended to help you understand our privacy practices and
                    how we collect, use, disclose and process your personal
                    information. We also describe your rights and choices with
                    respect to how we process your personal information. This
                    Policy applies to www.{domainName} and all other websites
                    and domains on which a link to this Policy appears
                    (including all subdomains, collectively the "Site(s)"). This
                    Policy does not apply to any other websites owned or
                    operated by Traffic Box LLC that do not link to this Policy,
                    terms for which are included with those sites individually.
                    We have a "data controller" or direct relationship with all
                    individuals accessing the Sites, submitting personal
                    information via the Sites for any reason, or calling any
                    phone number posted on our Sites.
                </p>
                <p>
                    At {domainName}, accessible at {domainName}, one of our main
                    priorities is the privacy of our visitors. This Privacy
                    Policy document contains types of information that is
                    collected and recorded by Website Name and how we use it.
                </p>
                <p>
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us
                    through email at{" "}
                    <a
                        href={`mailto:info@${
                            actualDomainName ??
                            (domain?.toLowerCase() as string)
                        }`}
                    >
                        info@{niceName ?? domain}
                    </a>
                    .
                </p>
                <p>
                    This privacy policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collect in Website Name.
                    This policy is not applicable to any information collected
                    offline or via channels other than this website.
                </p>
                <p>
                    <strong>Consent:</strong> By using our website, you hereby
                    consent to our Privacy Policy and agree to its terms.
                </p>
            </section>
            <section>
                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may revise this Policy from time to time and without
                    prior notice to you. Except as otherwise noted in this
                    Policy, such changes may apply to any personal information
                    we already hold about you or personal information collected
                    after the Policy is modified. Please visit this page
                    regularly so that you are aware of our latest updates.
                    Continuing to access or use this Site after any changes
                    become effective indicates your acceptance of the revised
                    Policy.
                </p>
                <p>
                    In addition, we may provide you with "just-in-time"
                    disclosures or additional information about the data
                    processing practices of specific parts of our Site. Such
                    notices may supplement this Policy or provide you with
                    additional choices about how we process your personal
                    information.
                </p>
            </section>
            <section>
                <h2>Third Parties</h2>
                <p>
                    This Policy does not apply to information processed by third
                    parties, for example, when you visit a third party website
                    or interact with third party services including those you
                    may access by following a link from the Sites or those with
                    whom we may share information as set forth in this Policy.
                    Please review any third parties’ privacy policies before
                    disclosing information to them.
                </p>
                <p>
                    When you call a number posted on the Sites or otherwise
                    submit an inquiry for an estimate, quote or service, we
                    share this information with our network of Partners (each, a
                    "Partner"), who pay us to provide this service, to enable
                    them to match you with contractors ("Contractors" or each, a
                    "Contractor") who can provide you with estimates and other
                    information related to your inquiry. Our terms of service
                    require that Partners utilize Personal Data in accordance
                    with all applicable local, state and federal laws, rules and
                    regulations. Note that any Personal Data transferred to our
                    Partners or Contractors as part of our services is no longer
                    under our control. Each Partner and Contractor has its own
                    Privacy Policy, and you should contact those Partners and
                    Contractors directly regarding their use of your Personal
                    Data.
                </p>
            </section>
            <section>
                <h2>Personal Information</h2>
                <p>
                    "Personal Data" means any information about an identified or
                    identifiable individual and any performance, log and cookie
                    information that may be linked with an identifiable
                    individual. We collect and process the following types of
                    information. Note: Specific Personal Data elements listed in
                    example are provided for example only and may change. We may
                    create anonymous records from Personal Data for certain
                    business purposes of ours, of our Partners and of our
                    Affiliates as defined below. Any information that is
                    anonymized or aggregated is no longer Personal Data and we
                    may indefinitely use it, share it and retain it for any
                    reason.
                </p>
                <p>
                    "Contact Data": Personal Data about you used to contact you.
                    For example: your name, company name, title, email address,
                    physical address, phone number, and/or username.
                    "Performance & Log Data": Information created by your use of
                    our Sites. For example: your IP address, browser type,
                    operating system, command line information, diagnostic
                    information related to the Site (i.e. crash activity
                    reports), the referring webpage, pages visited, date, your
                    geo-location, your mobile carrier, your device and
                    application IDs and search terms. Note that Depending on the
                    law of your country of residence, your IP address may
                    legally be considered personally identifiable information.
                </p>
                <p>
                    "Cookies": A cookie is a small amount of data generated by a
                    website and saved by your web browser. Its purpose is to
                    remember information about you, similar to a preference file
                    created by a software application. In some cases, Cookies
                    and similar automated data collection technologies may be
                    used to collect personal information, or information that
                    becomes personal information if we combine it with other
                    information.
                </p>
                <p>
                    "Web Pixels": A web pixel is small HTML code snippet which
                    is loaded when a user visits a website or opens an email. It
                    is used for tracking user behavior on our Sites such as
                    referring sites and form submissions. In some cases, Web
                    Pixels may be used to collect personal information, or
                    information that becomes Personal Data if we combine it with
                    other information.
                </p>
                <p>
                    "Other Data": Any other information that an individual
                    provides to us. For example: survey responses, blog
                    comments, or other communication submitted to us.
                </p>
            </section>
            <section>
                <h2>Collection and Processing</h2>
                <p>
                    We collect your Personal Data through our Sites and over the
                    phone when you call an inquiry number listed on our Sites.
                    Our Sites are public, any information that is disclosed on
                    our Sites may appear on search engines, or other publicly
                    available platforms and may be "crawled," searched and used
                    by unaffiliated third parties. Please do not post any
                    information that you do not want to reveal publicly and
                    please do not call us from any phone number you do not give
                    us permission to utilize and share as provided below.
                </p>
                <p>
                    Quote Requests: When you submit a request for an estimate,
                    quote or other information through our Sites, we may use
                    your Personal Data to match your request with a Partner or
                    Contractor in our network. Our "Partner Network" is a
                    collection of Partners that have networks of local
                    Contractors to provide {category}.
                </p>
                <p>
                    Customer Service: When you contact us through the Sites,
                    including submitting a "contact us" or other online inquiry
                    form, subscribing to a newsletter or blog, downloading a
                    whitepaper or other informational document, entering a
                    contest, registering for a demo or webinar, completing an
                    online survey or any other means, we may record your Contact
                    Data in our customer relationship management system and use
                    your Personal Data to respond to you, or provide this
                    information to a Partner so that the Partner or Contractor
                    may respond to you. If you provide a mobile phone number to
                    us, you are explicitly granting us and our Partners and
                    Contractors permission to send text messages to that number
                    to respond to your request and to contact you at that number
                    via an auto-dialer or with a recorded message, which we may
                    do at our discretion.
                </p>
                <p>
                    Marketing: We may use your Personal Data to keep you updated
                    about our products and services and send you promotional
                    material about {domainName} and participating Partners and
                    as permitted by applicable law, on behalf of our parent
                    company, affiliates, subsidiaries, joint ventures, or other
                    companies under common control with us (collectively,
                    "Affiliates") and partner companies. Promotional materials
                    may include marketing communications, online surveys,
                    notifications regarding our events and webinars and those of
                    our Partners, Contractors, Affiliates and other third-party
                    partners. If you provide a mobile phone number to us, you
                    are explicitly granting us and our Partners and Contractors
                    permission to send text messages, recorded messages, and/or
                    use an auto-dialer to contact that number for marketing and
                    promotional purposes, which we or our Partners and
                    Contractors may do at our discretion. You may opt-out of our
                    marketing communications at any time.
                </p>
                <p>
                    Cookies & Similar Tech: When you access the Sites or open
                    one of our HTML emails, we may automatically record
                    Performance & Log Data by using Cookies, Web Pixels, web
                    beacons, click-stream tracking and similar automated data
                    collection technologies. We use this Personal Data for
                    essential and functional purposes including for site
                    administration, to improve the performance and usability of
                    the Sites, and analyze how users interact with the Sites. On
                    certain portions of our Sites, we may collect Personal Data
                    through these technologies for advertising, remarketing or
                    other similar purposes.
                </p>
                <p>
                    Security: We process your Personal Data to enhance the
                    security of our Sites and to combat spam, malware or other
                    security risks. This may include monitoring your activities
                    on our Sites. Without processing your Personal Data for such
                    purposes, we may not be able to ensure the security of our
                    Sites.
                </p>
                <p>
                    Additional Processing: If we process Personal Data in
                    connection with your use of the Sites in a way not described
                    in this Policy, this Policy will still apply generally (e.g.
                    with respect to Your Rights and Choices) unless otherwise
                    stated when you provide it.
                </p>
            </section>
            <section>
                <h2>Sharing</h2>
                <p>
                    Information we collect may be shared with a variety of
                    parties depending upon the purpose for and context in which
                    that information was provided. In all cases where we share
                    Personal Data with third party service providers acting on
                    our behalf, we will use a "minimum necessary" standard to
                    disclose only that information required for satisfying the
                    purpose of or performing the service for which the
                    information is disclosed. We generally transfer Personal
                    Data according to the following:
                </p>
                <p>
                    Consent: We will share your Personal Data in accordance with
                    your consent for us to do so. By submitting any Personal
                    Data via our Sites or by calling a number published on our
                    Sites you are providing consent for us to share your
                    Personal Data with Partners in our Partner Network, and for
                    those Partners to share your Personal Data with Contractors.
                </p>
                <p>
                    Partner Network: When you submit a request for a quote, we
                    may share your Personal Data with Partners in our Partner
                    Network who pay us to provide this service. Our Partners may
                    use your Personal Data to connect you with a Contractor to
                    assist you with your {category} projects and may share your
                    Personal Data with such Contractor in order to contact you
                    to offer its services. If you provide a mobile phone number
                    to us you are explicitly granting us, and any Partner or
                    Contractor with whom you are matched by our service,
                    permission to call and send text messages to that number to
                    respond to your request and to contact you at that number
                    via phone call, text message, an auto-dialer or recorded
                    message, which we or our Partners or Contractors may do at
                    our discretion. Note that Partners and Contractors are third
                    parties. Any Personal Data transferred to a Partner or
                    Contractor as part of our services, is no longer under our
                    control and is subject to the privacy policies of such
                    Partner or Contractor.
                </p>
                <p>
                    Service Providers: In connection with our general business
                    operations, to enable certain features, and in connection
                    with our other legitimate business interests, we may share
                    your Personal Data with service providers or sub-processors
                    who provide certain services or process data on our behalf.
                    Our contracts with these service providers dictate that they
                    only use your information in connection with the services
                    they perform for us and you consent to our sharing of
                    information with these parties by using our Sites subject to
                    this Policy.
                </p>
                <p>
                    Affiliates: In order to streamline certain business
                    operations, develop products and services that better meet
                    the interests and needs of our customers, and inform our
                    customers about relevant products and services, we may share
                    your Personal Data with any of our current or future
                    Affiliates. You hereby agree to our sharing some or all of
                    your information and Personal Data with our Affiliates.
                </p>
                <p>
                    Third-Party Partners: When you complete an online inquiry
                    form to which you were referred by a third-party partner,
                    any information collected through an online inquiry form
                    hosted on the Sites may be shared with the referring third
                    party partner. We may share your Personal Data with third
                    parties for marketing or adverting purposes, as permitted by
                    law. For example, when you sign up for a webinar co-hosted
                    by us and a third-party partner, we may share your Personal
                    Data with the third-party partner. Third party partners may
                    use your Personal Data for their own purposes subject to
                    their own privacy policies.
                </p>
                <p>
                    Business Transactions: Your Personal Data may be processed
                    in the event of a business transaction, such as a merger,
                    acquisition, liquidation, or sale of all or a portion of our
                    assets. For example, Personal Data may be disclosed (subject
                    to confidentiality restrictions) during the due diligence
                    process for a potential transaction or may part of the
                    assets transferred, in such case the acquiring company will
                    possess any rights granted to us under this Policy.
                </p>
                <p>
                    Legal Disclosures: In limited circumstances, we may without
                    notice to you or your consent, access and disclose your
                    Personal Data any communications sent or received by you,
                    and any other information that we may have about you to the
                    extent we believe such disclosure is legally required, to
                    prevent or respond to a crime, to investigate violations of
                    our Terms of Use, or in the vital interests of us or any
                    person. Note, these disclosures may be made to governments
                    that do not ensure the same degree of protection of your
                    Personal Data. We may, in our sole discretion (but without
                    any obligation), object to the disclosure of your Personal
                    Data to such parties.
                </p>
            </section>
            <section>
                <h2>Retention</h2>
                <p>
                    We retain Personal Data for so long as necessary to service
                    the purpose(s) for which your Personal Data was processed
                    and for a reasonable time thereafter, or as necessary to
                    comply with our legal obligations, to resolve disputes or
                    enforce our agreements. While retention requirements can
                    vary by jurisdiction, we generally apply the retention
                    periods noted below:
                </p>
                <p>
                    Site Activity: We may store any information about your
                    activity on our Sites or any Other Data created, posted or
                    shared by you on our Sites for as long as we deem it
                    necessary or until you provide specific instructions to
                    delete it, which may be indefinitely, or where a valid
                    business reason exists for such storage such as maintaining
                    the integrity of our systems and logs or for the
                    establishment or defense of legal claims, audit and crime
                    prevention purposes.
                </p>
                <p>
                    Marketing: We store information used for marketing purposes
                    indefinitely until you unsubscribe. When you unsubscribe
                    from marketing communications, we add your contact
                    information to our suppression list to ensure we respect
                    your unsubscribe request.
                </p>
                <p>
                    Cookie Data: We retain any information collected via
                    cookies, clear gifs, flash cookies, webpage counters and
                    other technical or analytics tools up to one year from the
                    expiry of the cookie or date of collection. Cookies owned by
                    third parties may have other retention periods.
                </p>
                <p>
                    Call Recording: As required by applicable law, we will
                    inform you that a call will be recorded before doing so. Any
                    recorded telephone calls with you may be retained for a
                    period of up to six years.
                </p>
                <p>
                    Note that any Personal Data transferred to our Partners or
                    Contractors as part of our services is no longer under our
                    control. While our terms of service require that Partners
                    and Contractors utilize Personal Data in accordance with all
                    applicable local, state and federal laws, rules and
                    regulations, each Partner and Contractor has its own data
                    retention policy. You should contact those Partners or
                    Contractors directly regarding their retention of your
                    Personal Data.
                </p>
            </section>
            <section>
                <h2>Your Rights and Choices</h2>
                <div>
                    <h3>Rights</h3>
                    <p>
                        You have the following rights in your Personal Data, in
                        each case to the extent required/permitted under
                        applicable law, and subject to our rights to limit or
                        deny access or disclosure under applicable law.
                    </p>
                </div>
                <div>
                    <h3>Access</h3>
                    <p>
                        You may request a list of your Personal Data that we
                        process by submitting an official request in writing via
                        email to address provided below.
                    </p>
                </div>
                <div>
                    <h3>Rectification</h3>
                    <p>
                        You may correct any Personal Data that we hold about you
                        by emailing us at the address provided below and
                        indicating both the inaccurate and corrected
                        information.
                    </p>
                </div>
                <div>
                    <h3>Erasure</h3>
                    <p>
                        You may request that we delete your Personal Data from
                        our systems once per year by making an official request
                        in writing via email to the address provided below and
                        indicating the specific information you would like
                        permanently deleted from our systems.
                    </p>
                </div>
                <div>
                    <h3>Data Export</h3>
                    <p>
                        You may request a copy of your Personal Data in a common
                        portable format of our choice by submitting an official
                        request in writing via email to the address provided
                        below.
                    </p>
                    <p>
                        We may require that you provide additional Personal Data
                        to exercise these rights, e.g. information necessary to
                        prove your identity. We also reserve the right to retain
                        certain account information for our recordkeeping or
                        compliance purposes.
                    </p>
                    <p>
                        Note that any Personal Data transferred to our Partners
                        or Contractors as part of our services is no longer
                        under our control. Our terms of service require that
                        Partners and Contractors utilize Personal Data in
                        accordance with all applicable local, state and federal
                        laws, rules and regulations. Each Partner and Contractor
                        has its own policies and procedures for you to exercise
                        your rights. You should contact those Partners and
                        Contractors directly regarding exercising your rights
                        regarding your Personal Data.
                    </p>
                </div>
                <div>
                    <h3>Choices</h3>
                    <p>
                        It is possible for you to access and use the Sites
                        without providing any Personal Data, but you may not be
                        able to access certain features or view certain content
                        and some portions of the Sites may not function
                        properly. You have the following choices regarding
                        Personal Data we process:
                    </p>
                    <p>
                        <strong>Consent:</strong> If you consent to processing
                        you may withdraw your consent at any time to the extent
                        required by law.
                    </p>
                </div>
                <div>
                    <h3>Opt-Out</h3>
                    <p>
                        Any communications from us that are not service-related
                        or transactional in nature will offer you an
                        "unsubscribe" option. You may opt-out of receiving
                        marketing communications from us by following the
                        opt-out instructions included in such communications or
                        contacting us at the email provided below. It may take
                        up to 30 days for us to process an opt-out request. We
                        may send you other types of transactional and
                        relationship email communications including service
                        announcements, administrative notices, and surveys,
                        without offering you the opportunity to opt-out of
                        receiving them. To the extent required by law, you may
                        choose to opt-out of sharing Personal Data with third
                        parties. Note that any Personal Data transferred to our
                        Partners and Contractors as part of our services is no
                        longer under our control. Our terms of service require
                        that Partners utilize Personal Data in accordance with
                        all applicable local, state and federal laws, rules and
                        regulations. Each Partner and Contractor has its own
                        opt-out procedures, and you should contact those
                        Partners and Contractors directly regarding their
                        opt-out policies and procedures.
                    </p>
                </div>
                <div>
                    <h3>Telemarketing</h3>
                    <p>
                        We have adopted and adhere to policies that comply with
                        applicable Federal Trade Commission, Federal
                        Communications Commission and state laws/regulations. If
                        you do not want to receive non-informational calls from
                        us, you can ask us to place your telephone number(s) on
                        our internal Do Not Call List by emailing us at the
                        contact information provided below. Your request must
                        include, at minimum, your telephone number and name. If
                        you have multiple telephone numbers, please be sure to
                        include all numbers that you would like included on our
                        Do Not Call List in your request. Any phone call, e-mail
                        or any other form of communication used to confirm
                        receipt of your Do Not Call request will not be deemed
                        to have violated this Policy. Your request will be
                        honored within thirty days of the request in compliance
                        with federal and state laws. Note that any phone
                        information transferred to our Partners or Contractors
                        as part of our services is no longer under our control.
                        While our terms of service require that our Partners and
                        Contractors utilize this contact information in
                        accordance with all applicable local, state, and federal
                        laws, rules, and regulations including TCPA, each
                        Partner and Contractor has its own Do Not Call List and
                        you must contact each Partner and Contractor
                        individually to be placed on their internal Do Not Call
                        Lists.
                    </p>
                </div>
                <div>
                    <h3>Cookies</h3>
                    <p>
                        If you do not want information collected through the use
                        of cookies, you can manage/deny cookies (and certain
                        technologies) using your browser’s settings menu or by
                        using a variety of tools.
                    </p>
                    <p>You can visit the Google Ads Settings page here.</p>
                    <p>
                        You can use the Google Analytics Opt Out Browser add on.
                    </p>
                    <p>
                        Digital Advertising Alliance’s opt-out page here allows
                        you to opt out from receiving third party advertiser
                        cookies and Web Pixels.
                    </p>
                    <p>
                        You can visit the Network Advertising Initiative opt-out
                        page here.
                    </p>
                    <p>
                        You can control Facebook’s use of interest-based ads
                        through your Facebook account settings or can visit the
                        customer support page here.
                    </p>
                    <p>
                        To learn more about cookies and similar tracking
                        technologies, and how they can affect your privacy,
                        visit allaboutcookies.org.
                    </p>
                    <p>
                        As there is no consistent industry understanding of how
                        to respond to "Do Not Track" signals, we do not alter
                        our data collection and usage practices when we detect
                        such a signal from your browser.
                    </p>
                </div>
            </section>
            <section>
                <h2>Collection of Personal Information</h2>
                <p>
                    We collect Personal Information that identifies, relates to,
                    describes, references, is capable of being associated with,
                    or could reasonably be linked, directly or indirectly, with
                    an individual Consumer, household, or device. We have
                    collected the following categories of Personal Information
                    from Consumers within the last twelve months:
                </p>
                <p>
                    The following examples are taken directly from the CCPA,
                    where we indicate that a category is collected, we collect
                    at least one of the example items from each category.
                </p>
                <p>
                    We obtain the categories of Personal Information listed
                    above from the following categories of sources:
                </p>
                <p>
                    Directly and indirectly from activity on our Sites. For
                    example, from customer submissions through our website or
                    customer usage details collected automatically.
                </p>
                <p>
                    Directly from consumers accessing our Sites and utilizing
                    our services. For example, from phone calls made to us,
                    emails sent to us, and form data submitted to us from Sites.
                </p>
                <p>From third parties. For example, Google and Facebook.</p>
                <div>
                    <h3>Sharing of Personal Information</h3>
                    <p>
                        Personal Information we collect may be shared with
                        service providers who provide certain services on our
                        behalf, including but not limited to identity
                        verification, fraud prevention, business intelligence,
                        customer relationship management, bill collection,
                        payment processing, marketing, hosting, and other common
                        technology services. Our contracts with these service
                        providers dictate that they only use Consumer
                        information in connection with the services they perform
                        for us and you consent to our sharing of information
                        with these parties by using our Sites subject to this
                        Policy.
                    </p>
                    <p>
                        To exercise the right to opt-out, you, or your
                        authorized representative, may submit an opt-out request
                        to us using this form, or by emailing or calling us at
                        the contact information provided below. You are not
                        required to create an account with us to submit an
                        opt-out request. Personal Information provided in an
                        opt-out request will only be used to verify the
                        requestor’s identity or authority to make the request
                        and to comply with the request.
                    </p>
                    <p>
                        Following the fulfillment of your opt-out request, we
                        will wait at least twelve months before asking you to
                        reauthorize Personal Information sales. However, you may
                        opt-in to Personal Information sales at any time, by
                        contacting us using any method in the Contact
                        Information section.
                    </p>
                    <p>
                        Non-Discrimination: We will not discriminate against you
                        for exercising any of your CCPA rights. Unless permitted
                        by the CCPA: we will not deny you goods or services;
                        charge you different prices or rates for goods or
                        services, including through granting discounts or other
                        benefits, or imposing penalties; provide you a different
                        level or quality of good or services; or suggest that
                        you may receive a different price or rate for goods or
                        services or a different level or quality of goods or
                        services.
                    </p>
                </div>
            </section>
            <section>
                <h2>Security</h2>
                <p>
                    We have implemented reasonable security measures designed to
                    secure your Personal Data from accidental loss, unauthorized
                    access, use, alteration and disclosure, however we do not
                    provide any guarantee that your Personal Data will remain
                    secure. We are not responsible for circumvention of any
                    privacy settings or security measures contained on the
                    Sites. All information you provide to us is stored on our
                    service providers’ servers which utilize industry standard
                    security controls; however, we do not have control over and
                    will not be liable for third parties’ security processes.
                    Any transmission of information or Personal Data is at your
                    own risk
                </p>
            </section>
            <section>
                <h2>Minors</h2>
                <p>
                    Our services are neither directed at nor intended for direct
                    use by individuals under the age of 18 or the age of
                    majority in the jurisdiction where they reside. Further we
                    do not intentionally gather information about such
                    individuals. If we learn that we have inadvertently done so,
                    we will promptly delete it. Do not access or use the Sites
                    if you are not the age of majority in your jurisdiction
                    unless you have the consent of your parent or guardian.
                </p>
            </section>
            <section>
                <h2>International Transfers</h2>
                <p>
                    We operate in the United States. Our Sites may be accessed
                    from anywhere, and users outside of our target United States
                    markets may submit their Personal Data to us via forms,
                    comment fields, or other input mechanisms. If you are
                    accessing the Sites from outside the United States, or
                    submitting your Personal Data to us from outside the United
                    States, your Personal Data may be transferred to, stored, or
                    processed in the United States and maintained on computers
                    or servers located outside of your state, province, country,
                    or other governmental jurisdiction where the privacy laws
                    may not be as protective those in your jurisdiction. By
                    accessing our Sites, you understand and consent to the
                    transfer of your information to the United States and to
                    those third parties with whom we share it as described in
                    this Policy, whether or not we can currently serve your
                    jurisdiction.
                </p>
            </section>
            <section>
                <h2>Contact Information</h2>
                <p>
                    Email:{" "}
                    <a
                        href={`mailto:info@${(
                            actualDomainName ?? domain
                        ).toLowerCase()}`}
                    >
                        info@{actualDomainName ?? domain}
                    </a>
                </p>
            </section>
        </div>
    );
}
